import BoxCenter from "./BoxCenter";
import React from "react";
import WarningIcon from "./icon/WarningIcon";


export default function InfoBox({

        title = "Unable to perform transaction",
        children,
        buttonText,
        onClick,
        onHide,
        asSinglePageInCenter = false,
        icon = <span className="text-yellow-500 block"><WarningIcon/></span>

    }: { icon?:any, title?: string, children: any; buttonText: string; onClick: any; onHide?: any; asSinglePageInCenter?: boolean }){


    let Content = ()=>(
        <div className={"bg-gray-100 p-3 rounded " +  (asSinglePageInCenter? "shadow-xl": "pb-4")}>
            <div className="text-center">
                <span className="text-yellow-500 block">{icon}</span>
            </div>

            <div className="text-center mt-1">
                <div className="text-alatpay-dark text-base">{title}</div>
                <p className="text-gray-500 inline-block mt-1 text-sm">{children}</p>

                <div className="mt-3 mb-3 text-sm">
                    <button className="btn btn-primary py-1 px-4 rounded-full " onClick={onClick}> { buttonText } </button>
                </div>

                {
                    onHide?
                        <div>
                            <button onClick={onHide} className="link text-alatpay-dark">Hide</button>
                        </div>:
                        null
                }
            </div>

        </div>
    )


    return (
        asSinglePageInCenter? <BoxCenter><Content/></BoxCenter>: <Content/>
    )
}
