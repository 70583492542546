import React from "react";
import { Link } from "react-router-dom";
import logo80 from "../../img/logo80.png";
import BoxCenter from "../../components/BoxCenter";
import LockIcon from "../../components/icon/LockIcon";
import CloseDialog from "../../components/CloseDialog";
import { useAppSetup } from "../../components/providers/AppSetupProvider";
import {
	capitalizeWords,
	formatCurrency,
	ifEmptyOrNull,
} from "../../helper/Formater";
import { useNotification } from "../../components/providers/NotificationProvider";
import NotificationModel from "../../types/NotificationModel";
import { AppSetupModel } from "../../types/AppSetupModel";
import PaymentMethods from "../../types/PaymentMethods";

export default function MainContainer({
	children,
	active,
	activeButton,
	onClose,
}: any) {
	let setupConfig: AppSetupModel = useAppSetup();
	let notification: NotificationModel = useNotification();

	return (
		<BoxCenter>
			<div className="h-auto bg-gray-100 w-80 sm:w-600 rounded">
				<CloseDialog onClick={onClose} />
				<div className="p-3">
					<div className="grid grid-span-2 sm:grid-cols-3 text-xs">
						<div className="col-span-2 mt-3 sm:col-span-1">
							<div>
								<strong className="text-alatpay-dark">Payment Methods</strong>
							</div>
							<div className="mt-1 mb-2 text-gray-700">
								How do you want to pay?
							</div>
							{PaymentMethods(
								setupConfig.merchantConfig.business.paymentMethodIds
							).map((value) => {
								return (
									<Link
										to={value.link}
										onClick={() =>
											setupConfig.updateAppConfig({
												currentPaymentMethod: value,
											})
										}
										key={value.id}
									>
										<button
											className={`mt-2 btn btn-primary py-2 px-2 w-full sm:w-11/12 text-left whitespace-nowrap"
												${active === value.link ? "btn-active" : ""}`}
											disabled={activeButton ? active !== value.link : false}
										>
											{value.icon} {value.title}
										</button>
									</Link>
								);
							})}
						</div>

						<div className="col-span-2 mt-3">
							<div className="bg-white pt-3 pb-2 px-3 text-gray-700">
								<div>
									<img
										src={logo80}
										alt="App Icon"
										className="float-left"
										style={{ height: "35px" }}
									/>
									<div className="float-right text-right">
										<div className="mt-1">
											Pay&nbsp;
											<span className="text-alatpay-dark font-bold">
												{formatCurrency(
													setupConfig.merchantConfig.amount,
													setupConfig.merchantConfig.currency
												)}
											</span>
											&nbsp;to
										</div>
										<div className="mt-1">
											<span>
												{capitalizeWords(
													ifEmptyOrNull(
														setupConfig.merchantConfig.businessName,
														setupConfig.merchantConfig.business.name
													)
												)}
											</span>
										</div>
									</div>
								</div>
								<div className="clear-both" />
								<div className="mt-3 border-t-2 border-dotted border-gray-200" />

								<div>{children}</div>
							</div>

							<div className="text-center mt-4 mb-2 text-gray-600">
								{notification.renderNotifier()}
								<span className="">
									<LockIcon />
								</span>{" "}
								Secured by <span className="font-bold">ALATPay</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BoxCenter>
	);
}
