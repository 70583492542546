import React from "react";
import CancelIcon2 from "./icon/CancelIcon2";
import { SendCloseDialogEvent } from "../helper/ParentPage";
// import {__} from "../configs/localizations";
import InfoBox from "./InfoBox";

export default function InfoBoxFailedTransaction({
	transactionId,
	errorMessage,
}: {
	transactionId?: any;
	errorMessage?: string;
}) {
	let Content = () => (
		<InfoBox
			icon={
				<span className="text-alatpay-dark block">
					<CancelIcon2 />
				</span>
			}
			buttonText="Close"
			title="Transaction failed"
			onClick={() => SendCloseDialogEvent()}
		>
			{errorMessage || "Please try again, transaction failed."}
			<div className="mt-0 text-red-600 text-xs">
				<dt>
					<small>Ref: {transactionId}</small>
				</dt>{" "}
			</div>
		</InfoBox>
	);

	return <Content />;
}
