import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	useMemo,
} from "react";
import { v4 as uuid } from "uuid";
import MainContainer from "../layout/MainContainer";
import {
	formatCardExpiry,
	formatCardNumber,
	formatCurrency,
	formatNumberOnly,
} from "../../helper/Formater";
import { useAppSetup } from "../../components/providers/AppSetupProvider";
import CardResponseModel from "../../types/PaymentCard/CardResponseModel";
import HttpRequest from "../../helper/HttpRequest";
// import HttpRequest, { HttpNethoneRequest } from "../../helper/HttpRequest";
import { useNotification } from "../../components/providers/NotificationProvider";
import CardRequestModel from "../../types/PaymentCard/CardRequestModel";
import ApiResponse from "../../types/ApiResponse";
import { __ } from "../../configs/localizations";
import BoxCenter from "../../components/BoxCenter";
import CancelIcon from "../../components/icon/CancelIcon";
import { SendTransactionEvent } from "../../helper/ParentPage";
import LoadingButton from "../../components/LoadingButton";
import ValidateTransactionAndCloseDialog from "../../components/ValidateTransactionAndCloseDialog";
import HttpEventSource from "../../helper/HttpEventSource";
import { APIs } from "../../configs/apis";
// import nethonePlugin from "../../helper/NethonePlugin";

export default function PaymentCard() {
	const CARD_NUMBER_LENGTH = 16;

	let [, setBadRequestError] = useState(null);
	let [is3DSecurePopup, show3DSecurePopup] = useState(false);
	let [buttonLoader, startButtonLoader] = useState(false);
	// let [, setInquiryCompleted] = useState(false);
	// let [profillingCompleted, setProfillingCompleted] = useState(false);
	// let [, setProfilingDetails] = useState(null);
	// const attemptReferenceRef = useRef(uuid().split("-").join(""));
	const cardRef = useRef("");
	const [activeButton, setActiveButton] = useState<boolean>(false);

  let notification = useNotification();
  let { merchantConfig, appConfig, updateAppConfig, setCurrentView } =
    useAppSetup();

	let cardRequestObject: CardRequestModel = useMemo(
		() => new CardRequestModel(merchantConfig),
		[merchantConfig]
	);
	let cardResponseObject: CardResponseModel = new CardResponseModel();

	// const nethone = useCallback(
	// 	async () =>
	// 		await nethonePlugin(attemptReferenceRef.current, [
	// 			merchantConfig?.businessId?.split("-")[0],
	// 		]),
	// 	[merchantConfig?.businessId]
	// );

	useEffect(() => {
		// const profiling = nethone();
		// if (profiling && !profillingCompleted) {
		// 	profiling.then((res) => {
		// 		setProfillingCompleted(true);
		// 	});
		// }
		// if (!profiling && profillingCompleted) {
		// 	setProfillingCompleted(false);
		// }
		if (cardRef.current) {
			cardRequestObject.cardNumber = cardRef.current;
		}
		// }, [cardRequestObject, nethone, profillingCompleted]);
	}, [cardRequestObject]);

	let makeCardRequest = (
		url: any,
		requestBody: any,
		successCallback: any,
		nethoneRequest: boolean = false
	): Promise<ApiResponse> => {
		// request to backend
		// if (nethoneRequest) {
		// 	return HttpNethoneRequest("POST", url, requestBody)
		// 		.then((response) => {
		// 			setBadRequestError(null);
		// 			successCallback(response);
		// 			return response;
		// 		})
		// 		.catch((err) => {
		// 			setBadRequestError(err.message);
		// 			notification.notify(err.message);
		// 			return err;
		// 		})
		// 		.finally();
		// } else {
		return HttpRequest("POST", url, requestBody)
			.then((response) => {
				setBadRequestError(null);
				successCallback(response);
				return response;
			})
			.catch((err) => {
				setBadRequestError(err.message);
				notification.notify(err.message);
				return err;
			})
			.finally();
		// }
	};

	/**
	 * Send Nethone profiling details and some other details to the backend.
	 */
	// let wemaCardProfiling = async (): Promise<ApiResponse> => {
	// 	let url = APIs.CardInquiryMiddleware.baseUrl(); // "http://20.54.227.23/alatpaympgsmiddleware/api/v1/alatpay-inquiry"
	// 	let { amount, cardMonth, cardYear, customer, currency } = cardRequestObject;
	// 	let cardNumber = cardRef.current;

	// 	let { businessId } = merchantConfig;

	// 	let requestBody = {
	// 		firstSixCardNumber: cardNumber.substring(0, 6),
	// 		lastFourCardNumber: cardNumber.slice(cardNumber.length - 4),
	// 		cardExpiryYear: `20${cardYear}`,
	// 		cardExpiryMonth: cardMonth,
	// 		amount: Number(amount),
	// 		profiling_Reference: businessId,
	// 		attempt_Reference: attemptReferenceRef.current,
	// 		firstName: customer?.firstName,
	// 		lastName: customer?.lastName,
	// 		emailAddress: customer?.email,
	// 		currency,
	// 		country: "NG",
	// 		city: "Lagos",
	// 		address: "Lekki Phase 1",
	// 		postalCode: "105104",
	// 		gender: "Male",
	// 		region: "WAT",
	// 	};
	// 	return makeCardRequest(
	// 		url,
	// 		requestBody,
	// 		(response: any) => {
	// 			setInquiryCompleted(true);
	// 		},
	// 		true
	// 	);
	// };

  /**
   * Initialize and Validate Card
   *      This request is optional and only runs in the background to initial the transactionId(To Speed up request).
   *      If unable to RUN this function, The authenticateCard function will perform this responsibility together
   *      when it discovers there is no transactionId set with the request.
   */
  let initializeCard = async (): Promise<ApiResponse> => {
    cardRef.current = cardRequestObject.cardNumber;
    cardRequestObject.cardNumber = cardRef.current;

    return makeCardRequest(
      APIs.PaymentCard.masterCard.initialize(),
      {
        cardNumber: cardRequestObject.cardNumber,
        currency: merchantConfig.currency,
        businessId: merchantConfig.businessId,
      },
      (response: any) => {
        // init response model
        cardRequestObject.transactionId = cardResponseObject.transactionId =
          response.data.transactionId;
        cardRequestObject.orderId = cardResponseObject.orderId =
          response.data.orderId;
        cardResponseObject.gatewayRecommendation =
          response.data.gatewayRecommendation;
      }
    );
  };

  /**
   * Try to validate payment if successful and close dialog
   */
  let validatePaymentAndCloseDialog = () => {
    setCurrentView(<ValidateTransactionAndCloseDialog />);
  };

  /**
   * Make Card Request
   */
  const cardRequest = () => {
    makeCardRequest(
      APIs.PaymentCard.masterCard.authenticate(),
      cardRequestObject,
      (response: any) => {
        if (response.data.gatewayRecommendation !== 'PROCEED')
          return notification.notify(
            `${__.card_cannot_proceed}. ${response.message}`
          );

        // Init Response Model
        updateAppConfig({ paymentCardResponse: response.data });
        cardResponseObject.redirectHtml = response.data.redirectHtml;
        cardResponseObject.transactionId = response.data.transactionId;
        cardResponseObject.gatewayRecommendation =
          response.data.gatewayRecommendation;

        // @ts-ignore
        document.getElementById('3DSecureFrame').src =
          'data:text/html;charset=utf-8,' +
          cardResponseObject.redirectHtml.replace(
            '<iframe ',
            '<iframe frameBorder="0" '
          );
        setTimeout(() => show3DSecurePopup(true), 500);

				// Subscribe to payment complete update
				if (merchantConfig.enabledOnTransactionCallback) {
					// Subscribe to payment complete update
					HttpEventSource(
						`${APIs.PaymentCard.notification()}&transactionId=${
							cardResponseObject.transactionId
						}`,
						(data: ApiResponse) => {
							if (!appConfig.isTransactionValidated) SendTransactionEvent(data);
							//setTimeout(()=>SendCloseDialogEvent(), 5000);
							updateAppConfig({ isTransactionValidated: true });
						}
					);
				}
			}
		).finally(() => startButtonLoader(false));
	};

  /**
   * Fetch authentication dialog
   *      Use transactionId to cardRequest data to fetch a 3DSecure dialog for user to authenticate.
   */
  let authenticateCard = async () => {
    // Init Request Model
    cardRequestObject.amount = merchantConfig.amount.toString();
    cardRequestObject.currency = merchantConfig.currency;

    // validation
    if (
      cardRequestObject.cardNumber.length < CARD_NUMBER_LENGTH ||
      cardRequestObject.cardYear.length < 2 ||
      cardRequestObject.cardMonth.length < 1
    )
      return notification.notify(__.card_invalid);
    if (parseInt(cardRequestObject.amount) <= 0)
      return notification.notify(__.add_amount);

		startButtonLoader(true);

		// Card Inquiry
		// // TODO: Rename wemaCardProfiling to wemaCardInquiry
		// await wemaCardProfiling()
		//   .then((response: any) => {
		//     setProfilingDetails(response);

		//     if (
		//       response?.statusCode === 200 ||
		//       response?.data?.statusCode === 200
		//     ) {
		//       // Card Authentication
		//       return cardRequest();
		//     } else {
		//       notification.notify(response?.data?.message);
		//       startButtonLoader(false);
		//     }
		//   })
		//   .catch((error) => {
		//     setInquiryCompleted(false);
		//     notification.notify(error?.data?.message);
		//     startButtonLoader(false);
		//     return;
		//   });
		return cardRequest();
	};

	const handleButton = () => {
		setActiveButton(true);
		authenticateCard();
	};

	return (
		<>
			<div style={{ display: is3DSecurePopup ? "block" : "none" }}>
				<BoxCenter>
					<div className="mt-10 p-0 bg-white" style={{ width: "480px" }}>
						<div className="bg-alatpay-dark text-lg text-white p-2">
							<span className="float-left">Transaction Verification </span>
							<button
								className="float-right transition duration-500 ease-in-out hover:text-gray-100"
								onClick={() => {
									show3DSecurePopup(false);
									validatePaymentAndCloseDialog();
								}}
							>
								<CancelIcon />
							</button>
							<div className="clear-both" />
						</div>
						<iframe
							style={{
								border: "none !important",
								marginTop: "-8px",
								padding: "0",
								transition: "opacity 0.3s",
							}}
							title="hello Frame"
							id="3DSecureFrame"
							width="480"
							height="540"
							frameBorder={0}
						/>
					</div>
				</BoxCenter>
			</div>
			{/* {!is3DSecurePopup && profillingCompleted ? ( */}
			{!is3DSecurePopup ? (
				<MainContainer active="/paymentCard" activeButton={activeButton}>
					<div className="mt-3">
						<span>Enter card details below to make payment</span>
					</div>
					<form action="">
						<div className="mt-2 p-3 bg-gray-100 rounded">
							<div>
								<label htmlFor="" className="text-alatpay-dark font-bold">
									Card Number
								</label>
								<div className="mt-2">
									<input
										type="tel"
										maxLength={19}
										required={true}
										className="customer-input font-mono"
										placeholder="5324 5324 5324 5324"
										onChange={(event) => {
											event.target.value = formatCardNumber(event.target.value);
											cardRequestObject.cardNumber = formatNumberOnly(
												event.target.value
											);
											cardRequestObject.cardNumber.length >=
												CARD_NUMBER_LENGTH && initializeCard();
										}}
									/>
								</div>
							</div>

							<div className="grid grid-cols-2 gap-2 mt-3">
								<div className="col-span-1">
									<label htmlFor="" className="text-alatpay-dark font-bold">
										Expiry
									</label>
									<div className="mt-2">
										<input
											type="tel"
											maxLength={5}
											required={true}
											className="customer-input font-mono text-center"
											placeholder="MM/YY"
											onChange={(event) => {
												event.target.value = formatCardExpiry(
													event.target.value
												);
												// process value
												let [cardMonth, cardYear] =
													event.target.value.split("/");
												cardRequestObject.cardMonth = cardMonth;
												cardRequestObject.cardYear = cardYear;
											}}
										/>
									</div>
								</div>

								<div className="col-span-1">
									<label htmlFor="" className="text-alatpay-dark font-bold">
										CVV
									</label>
									<div className="mt-2">
										<input
											type="password"
											pattern="\d*"
											maxLength={4}
											className="customer-input font-mono text-center"
											onChange={(event) => {
												// process value
												event.target.value = formatNumberOnly(
													event.target.value
												);
												cardRequestObject.securityCode = event.target.value;
											}}
											placeholder="***"
										/>
									</div>
								</div>
							</div>
						</div>

						<LoadingButton
							isLoading={buttonLoader}
							defaultText={
								"Pay " +
								formatCurrency(merchantConfig?.amount, merchantConfig?.currency)
							}
							loadingText="Please wait..."
							onClick={() => !buttonLoader && handleButton()}
						/>
					</form>
				</MainContainer>
			) : null}
		</>
	);
}
