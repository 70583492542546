import React from "react";
import {__} from "../configs/localizations";
import InfoBox from "./InfoBox";
import PendingIcon from "./icon/PendingIcon";

export default function InfoBoxPendingTransaction({transactionId, onConfirmPayment, onHide}: {transactionId?: string, onConfirmPayment?: any, onHide?: any}){

    let Content = ()=>(
        <InfoBox
            icon={<span className="text-alatpay-dark block"><PendingIcon /></span>}
            buttonText="Check Again"
            title="Transaction is pending"
            onClick={onConfirmPayment}
            onHide={onHide}
            >{__.payment_is_pending}
            <div className="mt-1 text-red-400 text-xs"><dt><small>Ref: {transactionId}</small></dt> </div>
        </InfoBox>
    )

    return (
        <Content/>
    )
}
